
import {computed, defineComponent, PropType, ref, shallowRef} from "vue";
import TreeNode from "./AncestryTree/TreeNodeFlat.vue";
import {TraversalRules} from "./AncestryTree/AncestryTree";
import {Beast} from "../model/Bestiary";
import {translateMixin} from "@/i18n";
import BeastFamilyTree, {RelationName} from "@/modules/bestiary/model/BeastFamilyTree";
import beastsStore from "@/modules/bestiary/store/beastsStore";

type BeastNode = {
  relation: string,
  beast: Beast | null,
  treeOffset: number,
  treeLevel: number,
}


export default defineComponent({
  mixins: [
    translateMixin,
  ],
  props: {
    ancestryTree: {type: Object as PropType<BeastFamilyTree>, required: true},
    walkRelations: {type: Array as PropType<RelationName[]>, required: true},
    expandLevels: {type: Number, default: 1},
    rootNodeRelation: {type: String, default: 'root'},
  },
  setup(props) {
    const rules: TraversalRules<BeastNode> = {
      getChildren(node) {
        const ancestryTree: BeastFamilyTree = props.ancestryTree

        const createChildNode = (relation: RelationName) => {
          const treeOffset = ancestryTree.treeArithmetics.getRelativeOffset(node.treeOffset, relation)
          const beast = node.beast ? ancestryTree.getBeast(node.beast.lineage[relation]) : null

          return {
            beast,
            treeLevel: node.treeLevel + 1,
            relation,
            treeOffset,
          }
        }

        return props.walkRelations.map((relation) => createChildNode(relation))
      },
    }

    const traversalRules = ref(rules)
    const rootNode = computed<BeastNode>(() => {
      return {
        relation: props.rootNodeRelation,
        beast: props.ancestryTree.getBeastByPath(),
        treeLevel: 0,
        treeOffset: 0,
      }
    })

    const treeStyles = computed(() => {
      const styles: any = {
        '--display-levels': props.expandLevels + 1,
      }

      for (let i = 0; i < props.expandLevels; i++) {
        styles['--level-span-' + i] = 2 ** (props.expandLevels - i)
      }

      return styles
    })

    return {
      treeNodeComponent: shallowRef(TreeNode),

      rootNode,
      traversalRules,
      breedingStationName: beastsStore.getters.breedingStationName,

      treeStyles,
      getNodeStyle: (node: BeastNode) => {
        return '--tree-level: ' + node.treeLevel
          + '; --tree-offset: ' + node.treeOffset
          + '; --row-span: var(--level-span-' + node.treeLevel + ')'
      },
    }
  },
})
