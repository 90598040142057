
import {computed, defineComponent, PropType} from "vue";
import {TraversalRules} from "@/modules/bestiary/components/AncestryTree/AncestryTree";

export default defineComponent({
  name: 'TreeNode',
  props: {
    node: {type: Object, required: true},
    traversalRules: {type: Object as PropType<TraversalRules>, required: true},
    expandLevels: {type: Number, default: 0},
  },
  setup(props) {
    const childNodes = computed(() => {
      if (props.expandLevels <= 0) {
        return []
      }
      return props.traversalRules.getChildren(props.node)
    })

    return {
      childNodes,
    }
  },
})
